module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Playfair Display:200,300,400,500,600,700,800,900","Lato:200,300,400,500,600,700,800,900"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"NGNails","short_name":"NGNails","icon":"src/images/ngn-logo.png","start_url":"/","background_color":"#d4beb3","theme_color":"#d4beb3","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8bcfdc0db9378481b2cb272b8dd9b022"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress-experimental/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://www.blog-ngn.pl/graphql","schema":{"typePrefix":"Wordpress","queryDepth":15,"circularQueryLimit":5,"timeout":30000,"perPage":100,"requestConcurrency":15,"previewRequestConcurrency":10},"type":{"Post":{"limit":5000}},"verbose":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-62318221-2","head":true,"defer":false,"sampleRate":5,"siteSpeedSampleRate":10,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    }]
